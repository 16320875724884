import { Link } from "react-router-dom";

export default function MainCard(props) {
  if (props.direction === 'left') {
    return (
      <Link to={props.to} className='shadow p-5 rounded-xl m-3 transition ease-in-out hover:-translate-y-1 hover:scale-110 hover:-rotate-2 hover:bg-black hover:text-white duration-300'>
        <span className='text-xl font-bold'>{props.title}</span>
        <br></br>
        <span className='text font-normal'>{props.desc}</span>
      </Link>
    )
  }
  else if (props.direction === 'center') {
    return (
      <Link to={props.to} className='shadow p-5 rounded-xl m-3 transition ease-in-out hover:-translate-y-1 hover:scale-110 hover:bg-black hover:text-white duration-300'>
        <span className='text-xl font-bold'>{props.title}</span>
        <br></br>
        <span className='text font-normal'>{props.desc}</span>
      </Link>
    )
  }
  else if (props.direction === 'right') {
    return (
      <Link to={props.to} className='shadow p-5 rounded-xl m-3 transition ease-in-out hover:-translate-y-1 hover:rotate-2 hover:scale-110 hover:bg-black hover:text-white duration-300'>
        <span className='text-xl font-bold'>{props.title}</span>
        <br></br>
        <span className='text font-normal'>{props.desc}</span>
      </Link>
    )
  }
}